import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

function MyWork(props) {
  return (
    <Link to={props.links}>
      <Card border="light" bg="light" className="stretched-link">
        <Card.Img variant="top" src={props.image} />
        <Card.Body>
          <Card.Title>{props.cardTitle}</Card.Title>
        </Card.Body>
      </Card>
    </Link>
  );
}
export default MyWork;
