import { Button } from "react-bootstrap";
import MyWork from "../Cards/MyWork";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import work from "./work.json";
import { useEffect } from "react";
function Work() {
  useEffect(() => {
    console.log(work.cardTitle, "dgf");
  }, []);
  return (
    <div className="bg-light GridImages">
      <section className="text-center py-5">
        <h1 className="display-3 pb-4">Things I've Worked On</h1>
        <p>
          Something short and leading about the collection below—its contents,
          the creator, etc. Make it short and sweet, but not too short so folks
          don't simply skip over it entirely.
        </p>
        <p>
          <Button className="mx-1">Websites</Button>
          <Button className="mx-1">Graphic Design</Button>
        </p>
      </section>

      <section className=" mx-5">
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
          <Masonry columnsCount={3} gutter="50px">
            {work.map((item) => (
              <MyWork
                links={item.links}
                image={require(`../UI/Images/${item.image}`)}
                cardTitle={item.cardTitle}
                linkText={item.linkText}
              />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </section>
    </div>
  );
}
export default Work;
