import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Home from "./Pages/Home";
import Work from "./Pages/Work";
import WiCS from "./Pages/Portfolio/WiCS";

function AnimatedRoutes() {
  const location = useLocation();
  return (
    <AnimatePresence initial={false}>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact-me" element={<Contact />} />
        <Route path="/work" element={<Work />} />
        <Route path="/women-in-computer-science" element={<WiCS />} />
      </Routes>
    </AnimatePresence>
  );
}
export default AnimatedRoutes;
