import {
  Container,
  Row,
  Col,
  Form,
  Button,
  ButtonToolbar,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";

import emailjs from "@emailjs/browser";
import { useRef, useState } from "react";
import Paperclip from "../UI/Icons/Paperclip.svg";

function Contact() {
  //EMAIL
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_yybr7b4",
        "template_ia24iwt",
        form.current,
        "Kf9DlpQ5lhKczGGyn"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
    handleSubmit();
  };
  //FORM validation code here
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    //sendEmail();
  };

  //CHECKBOXES
  const [checked, setChecked] = useState(false);
  const [radioValue, setRadioValue] = useState("1");

  const radios = [
    { name: "UI/UX Design", value: "1" },
    { name: "Website", value: "2" },
    { name: "Graphic Design", value: "3" },
    { name: "Other", value: "4" },
  ];

  return (
    <div className="contact-form">
      <Container>
        <Row>
          <Col className="py-5 text-center my-auto" sm={12} md={6}>
            <div>
              <h1>Have a project? I'd love to help.</h1>
            </div>
          </Col>
          <Col className="py-5">
            <Form
              noValidate
              validated={validated}
              ref={form}
              onSubmit={sendEmail}
            >
              <Form.Group className="mb-3">
                <Form.Text>I'm interested in...</Form.Text>
              </Form.Group>

              <Form.Group className="mb-3">
                <ButtonToolbar>
                  <ButtonGroup className="me-2 mb-2">
                    <ToggleButton
                      id="toogle-check"
                      type="checkbox"
                      variant="outline-primary"
                      checked={checked}
                      value="1"
                      onChange={(e) => setChecked(e.currentTarget.checked)}
                    >
                      UI/UX Design
                    </ToggleButton>
                  </ButtonGroup>
                  <ButtonGroup className="me-2 mb-2">
                    <ToggleButton
                      id="toogle-check"
                      type="checkbox"
                      variant="outline-primary"
                      checked={checked}
                      value="1"
                      onChange={(e) => setChecked(e.currentTarget.checked)}
                    >
                      Websites
                    </ToggleButton>
                  </ButtonGroup>
                  <ButtonGroup className="me-2 mb-2">
                    <ToggleButton
                      id="toogle-check"
                      type="checkbox"
                      variant="outline-primary"
                      checked={checked}
                      value="1"
                      onChange={(e) => setChecked(e.currentTarget.checked)}
                    >
                      Graphic Design
                    </ToggleButton>
                  </ButtonGroup>
                  <ButtonGroup className="me-2 mb-2">
                    <ToggleButton
                      id="toogle-check"
                      type="checkbox"
                      variant="outline-primary"
                      checked={checked}
                      value="1"
                      onChange={(e) => setChecked(e.currentTarget.checked)}
                    >
                      Other
                    </ToggleButton>
                  </ButtonGroup>
                </ButtonToolbar>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  required
                  type="text"
                  placeholder="Your Name"
                  name="from_name"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter your first name
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  required
                  type="email"
                  placeholder="Your Email"
                  name="their_email"
                />
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  Please enter your email
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Control
                  required
                  type="text"
                  as="textarea"
                  placeholder="Tell us about your project"
                  rows={1}
                  name="message"
                />
                <Form.Control.Feedback type="invalid">
                  You cannot leave this blank
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formFile" className="mb-3">
                {/* <Form.Control type="file" /> */}
                <div className="UploadSection">
                  <Form.Control type="file" id="upload" hidden />
                  <label htmlFor="upload">
                    <img src={Paperclip} />
                  </label>
                  <span className="attachment-upload">Add Attachment</span>
                </div>
              </Form.Group>

              <Button variant="primary" type="submit">
                Send Request
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
      <section className="text-center display-5 py-5 bg-light">
        <p>We are optimists who love to work together</p>
      </section>
    </div>
  );
}
export default Contact;
