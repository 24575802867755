import { BrowserRouter as Router } from 'react-router-dom';
import AnimatedRoutes from './Components/AnimatedRoutes';
import Footer from './Components/Navigation/Footer';
import NavbarComp from './Components/Navigation/NavbarComp';
import "./index.css";


function App() {
  return (
    <div className="App">
      <Router>
      <NavbarComp />
      <AnimatedRoutes />
      <Footer/>
      </Router>
      
    </div>
  );
}

export default App;
