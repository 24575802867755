import { Row, Col, Button } from "react-bootstrap";
import CodeSlash from "../UI/Icons/CodeSlash.svg";
import Easel from "../UI/Icons/Easel.svg";
import GraphUpArraow from "../UI/Icons/GraphUpArrow.svg";
import { easeOut, motion as m } from "framer-motion";
import { Container } from "react-bootstrap";
import MyServices from "../Cards/MyServices";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <m.div
      className=" overflow-hidden"
      initial={{ y: "100%" }}
      animate={{ y: "0%" }}
      transition={{ ease: easeOut, duration: 1.25 }}
      exit={{ opacity: 1 }}
    >
      <div className="bg-primary p-5">
        <Row>
          <Col>
            <p className="text-success">Hi, my name is </p>

            <m.h1
              animate={{ y: 0 }}
              initial={{ y: "100%" }}
              transition={{ delay: 1, duration: 0.75 }}
              className="text-light my-5"
            >
              Ariana Wells
            </m.h1>

            <p className="text-success">
              I'm a full stack developer specializing in designing and
              developing and developing interactive, user friendly, and scalable
              applications. Check out some of my work below
            </p>
          </Col>
          <Col>
            {/* <m.div
              style={{
                width: 150,
                height: 150,
                borderRadius: 30,
                backgroundColor: "#fee",
              }}
              animate={{ rotate: 360 }}
              transition={{ ease: "linear", duration: 2, repeat: Infinity }}
            ></m.div>
            */}
          </Col>
        </Row>
      </div>
      <Container>
        <h3 className="text-center my-5">My Services</h3>
        <Row className="mb-5 gy-3">
          <Col sm={12} md={4}>
            <MyServices
              svgIcon={CodeSlash}
              title="Web Development"
              //text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
            />
          </Col>
          <Col sm={12} md={4}>
            <MyServices
              svgIcon={Easel}
              title="Graphic Design"
              //text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
            />
          </Col>
          <Col sm={12} md={4}>
            <MyServices
              svgIcon={GraphUpArraow}
              title="Search Engine Optimization"
              //text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
            />
          </Col>
        </Row>
      </Container>
      <div className="bg-secondary">
        <Container className="p-5 text-center">
          <p className="fs-3 text-success">
            "Original and with an innate understanding of her customer's needs,
            Ariana is always a pleasure to work with"
          </p>
          <footer className="blockquote-footer text-light">Jane Arnold</footer>
        </Container>
      </div>
      <div className="text-center p-5">
        <h2 className="fs-1">Questions?</h2>
        <p>
          whether you're curious about features, a free trial, or just want to
          know more about me, I'm here to answer any questions.
        </p>
        <Link to="/contact-me">
          <Button className="p-2">Let's Talk Now</Button>
        </Link>
      </div>
    </m.div>
  );
}
