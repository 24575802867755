import { easeOut, motion as m } from "framer-motion";
import { Col, Row } from "react-bootstrap";
import Easel from "../UI/Icons/Easel.svg";
import CodeSlash from "../UI/Icons/CodeSlash.svg";
import Database from "../UI/Icons/Database.svg";
import MySkills from "../Cards/MySkills";

function About() {
  return (
    <m.div
      className="overflow-hidden"
      initial={{ y: "100%" }}
      animate={{ y: "0%" }}
      transition={{ ease: easeOut, duration: 1.25 }}
      exit={{ opacity: 1 }}
    >
      <div className=" p-5 text-center display-2">
        <h1>About Me</h1>
      </div>
      <article className="text-center mb-5">
        <p>
          Hello! My name is Ariana, and I design and develop websites. My
          interest in web development began in 2019 when I decided to learn a
          new skill.
        </p>
        <p>
          Fast forward to today, and I've had the privelege of working on
          multiple student-led project and organizations. My fain focus these
          days is creating beautiful, accesible digital experiences for a
          variety of clients.
        </p>
      </article>
      <section>
        <h2 className="p-3 mb-5 text-center">Skills</h2>
        <div className="px-3">
          <Row>
            <Col className="mb-2" sm={4} md={4}>
              <MySkills
                svgIcon={Easel}
                title="Design"
                secondTitle="Mediums"
                text="mockups, wireframes, Infographics"
                thirdTitle="Software"
                secondaryText="Adobe XD, Illustrator, Photoshop, InDesign"
              />
            </Col>
            <Col className="mb-2" sm={4} md={4}>
              <MySkills
                svgIcon={CodeSlash}
                title="Development"
                secondTitle="Languages"
                text="HTML, CSS, JavaScript"
                thirdTitle="Frameworks"
                secondaryText="Express, Node, React, Bootstrap"
              />
            </Col>
            <Col className="mb-2" sm={4} md={4}>
              <MySkills
                svgIcon={Database}
                title="Databases"
                secondTitle="Relational"
                text="SQL, MySQL"
                thirdTitle="Non-Relational"
                secondaryText="MongoDB, Amazon SimpleDB"
              />
            </Col>
          </Row>
        </div>
      </section>
    </m.div>
  );
}
export default About;
