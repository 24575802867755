import { Card } from "react-bootstrap";

function MySkills(props) {
  return (
    <Card border="light" bg="light" className="mx-auto">
      <Card.Img
        variant="top"
        src={props.svgIcon}
        class="img-fluid w-25 mx-auto d-block p-3"
      />
      <Card.Body className="text-center">
        <Card.Title>{props.title}</Card.Title>
        <Card.Subtitle>{props.secondTitle}</Card.Subtitle>
        <Card.Text>{props.text}</Card.Text>
        <Card.Subtitle>{props.thirdTitle}</Card.Subtitle>
        <Card.Text>{props.secondaryText}</Card.Text>
      </Card.Body>
    </Card>
  );
}
export default MySkills;
