import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Resume from "../Resume/Resume.pdf";

function NavbarComp() {
  return (
    <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark">
      <Container>
        <Navbar.Brand as={Link} to="/">
          Logo
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            <Nav.Link className="mr-5" as={Link} to="/about">
              About
            </Nav.Link>
            <Nav.Link as={Link} to="/work">
              Work
            </Nav.Link>
            <Nav.Link as={Link} to="/contact-me">
              Contact Me
            </Nav.Link>
          </Nav>
          <Button variant="outline-success" href={Resume} download="resume">
            Resume
          </Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarComp;
