import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import Behance from "../UI/Icons/Behance.svg";
import Envelope from "../UI/Icons/Envelope.svg";
import LinkedIn from "../UI/Icons/LinkedIn.svg";

function Footer() {
  return (
    <Navbar bg="primary" variant="dark">
      <Nav>
        <Nav.Item>
          <Nav.Link className="text-white" href="https://www.behance.net/">
            <img src={Behance} alt="" />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="mailto: admin@arianawells.com">
            <img src={Envelope} alt="" />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="https://www.linkedin.com/in/ariana-wells-08a57a206/">
            <img src={LinkedIn} alt="" />
          </Nav.Link>
        </Nav.Item>
      </Nav>

      <Nav className="me-auto"></Nav>
      <Nav>
        <Nav.Link as={Link} to="/contact-me">
          Privacy Policy
        </Nav.Link>
      </Nav>
    </Navbar>
  );
}

export default Footer;
