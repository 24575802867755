import { Col, Container, Nav, Row, Image } from "react-bootstrap";
import OldWebsite from "../../UI/Images/OldWebsite.PNG";
import NewWebsite from "../../UI/Images/NewWebsite.PNG";
import OldCompetition1 from "../../UI/Images/OldCompetition1.PNG";
import NewProgComp from "../../UI/Images/NewProgComp.PNG";
function WiCS() {
  return (
    <section>
      <Container>
        <Row className="mb-5">
          <Col className="col-4">
            <Nav.Link href="https://asuwics.org/" target="_blank">
              <p className="fw-light text-decoration-underline font-size-sm">
                Launch Project
              </p>
            </Nav.Link>
          </Col>
          <Col>
            <p className=" fw-light">Type: Web Design & Development</p>
          </Col>
          <Col>
            <p className="fw-light">Client: WiCS</p>
          </Col>
          <Col>
            <p className="fw-light">Deliverables: Web Design</p>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col className="my-auto" lg={4} sm={12}>
            <h1 className="mb-5">Women in Computer Science</h1>
          </Col>
          <Col>
            <p className="fs-4">
              Computer Science is a tough major, and can be especially tough for
              young women entering a male dominated field. Women in Computer
              Science aims to support and empower young women by fostering
              education, meetups, and social events. With that in mind, I
              evolved the design from previous years and updated the concept to
              represent a more modern feel. Throughout the project, I reinforced
              the diversity of the organzization to subtly invoke WiCS core
              message: to be a support network for female and other minority
              students in technology.
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={4} sm={12}>
            <h1>Research</h1>
          </Col>
          <Col className="fs-4">
            <p>The initial researrch phase involved the following steps:</p>
            <ol>
              <li>Heuristic Evaluation</li>
              <li>User Research</li>
              <li>Usability Testing</li>
            </ol>
            <h4 className="my-5">Heuristic Evaluation</h4>
            <p>
              I conducted a heuristic evaluation to quickly find obvious
              usability issues at an inexpensive cost and to set a foundation
              for how to improve the site.
            </p>
            <p>
              I used Jakob Nielsen's 10 usability heuristics and rated each
              heuristic using Nielsen's severity ratings scale:
            </p>
            <ul>
              <li>
                <p>
                  {" "}
                  <span className="fw-bold">0 = Not a usability problem: </span>
                  I don't agree that this a usability problem at all
                </p>
              </li>
              <li>
                <p>
                  {" "}
                  <span className="fw-bold">1 = Cosmetic problem only: </span>I
                  don't agree that this a usability problem at all
                </p>
              </li>
              <li>
                <p>
                  {" "}
                  <span className="fw-bold">2 = Minor usability problem: </span>
                  I don't agree that this a usability problem at all
                </p>
              </li>
              <li>
                <p>
                  {" "}
                  <span className="fw-bold">3 = Major usability problem: </span>
                  I don't agree that this a usability problem at all
                </p>
              </li>
              <li>
                <p>
                  {" "}
                  <span className="fw-bold">4 = Usability catastrophe: </span>I
                  don't agree that this a usability problem at all
                </p>
              </li>
            </ul>
            <p>
              We found that the site mostly had cosmetic issues, along with a
              couple of major issues. This information served as a basis for our
              need to conduct a usability test and propose and redesigned
              website.
            </p>
            <h4 className="my-5">User Testing</h4>
            <p>
              {" "}
              To better understand the website's audience, an online survey was
              distributed across the students and we received a total of 16
              responses. The survey included questions regarding the patient's
              age, year of graduation, goals, and pain point regarding the
              original WiCS website. This data was crucial in identifying our
              design priorities and identifying our target audiences
            </p>
            <h4 className="my-5">Usability Testing</h4>
            <p>
              To find specific usability issues amongst users, we conducted a
              usabiltiy test on the existing website with 3 participants. The
              tests were done over Zoom and included 3 scenarios.
            </p>
            <p>
              Through our usability tests on the original website, the following
              key issues were observed:
            </p>
            <ul>
              <li>
                Participants had difficulty navigating the site on their IPhones
                due to the website not being optimized for mobile devices
              </li>
              <li>
                participants used to much trial and error in navigating the site
              </li>
            </ul>
          </Col>
        </Row>
        <h4 className="my-5 text-center">Design Solutions</h4>
        <p>
          I created a high-fidelity prototype to test potential design solutions
          to the issues discovered during testing. This prototype involved
          several changes as demonstrated below:
        </p>
        <Row className="mb-5">
          <Col lg={6}>
            <p>Original</p>
            <Image src={OldWebsite} className="img-fluid"></Image>
          </Col>
          <Col lg={6}>
            <p>Redesign</p>
            <Image src={NewWebsite} className="img-fluid"></Image>
          </Col>
        </Row>
        <p>
          One example of a design change is the programming competition. In the
          original design, Registration was near the bottom of the page, and
          information was poorly conveyed. In the redesign, The program
          information is a lot more organized, making information become much
          easier to find
        </p>
        <Row className="mb-5">
          <Col lg={6}>
            <p>Original</p>
            <Image src={OldCompetition1} className="img-fluid"></Image>
          </Col>
          <Col lg={6}>
            <p>Redesign</p>
            <Image src={NewProgComp} className="img-fluid"></Image>
          </Col>
        </Row>
        <h4>Results and Conclusion</h4>
        <p>
          In addition to the redesign, google analyitics was added to both the
          old and new website to track things like engagement and task time.
        </p>
        <Row className="mb-5">
          <Col lg={4}>
            <h5 className="text-center text-secondary">150%</h5>
            <p className="text-center">Average increase in web traffic</p>
          </Col>
          <Col lg={4}>
            <h5 className="text-center text-secondary">57%</h5>
            <p className="text-center">Average increase in time spent </p>
          </Col>
          <Col lg={4}>
            <h5 className="text-center text-secondary">28%</h5>
            <p className="text-center">
              Average increase in confidence in understanding the organization's
              mission
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default WiCS;
